<template>
    <div class="card box widget-social pt-2 pb-2" :class="[alarmStatus,componentStyle]">
        <widgetTitleBar :moduleData="this.moduleData" :widgetInfo="content" :alarmList="this.alarmList" :menu-items="this.items" @menu-click="($value) => this.toggle($value)"></widgetTitleBar>
        <OverlayPanel
            class="param-overlay"
            ref="alarmOverlay"
            appendTo="body"
            :autoZIndex="true"
            @show="focusElement" 
            :dismissable="true"
            :showCloseIcon="true"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ height: 'auto' }"
        >
            <div v-for="(element) in this.alarmList" :key="element.label">
                <span>🔴 {{ element.label }}</span>
            </div>
        </OverlayPanel>
        <div v-if="isLoading">
            <loader-skeleton :widgetInfo="content"></loader-skeleton>
        </div>
        <OverlayPanel class="param-overlay" ref="periods" appendTo="body" :autoZIndex="true" @show="focusElement" 
            :dismissable="false" :showCloseIcon="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ height: '300px' }">
        </OverlayPanel>
        <Dialog 
            v-model:visible="showDiagPhotons"
            @show="focusElement"
            :breakpoints="{ '960px': '85vw', '640px': '90vw' }"
            :style="{ width: '50vw' }"
            class="p-fluid"
            >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i>
                    <span class="subtext mt-1">
                        {{ moduleDepartment.key }} - {{ moduleDepartment.label }} ⚡ Photonenmixer Preset-Konfiguration
                    </span>
                </h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="diagTogglePhotons" @keydown.enter="saveDiag('photons')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView v-else :scrollable="true">
                    <TabPanel v-for="(element, index) in this.nodeDataPhotons" :key="index" :header="`${index+1}: ${this.getDiagDataPhotons(index, 'presetName').value}`">
                        <!-- <Panel :header="returnParameter(element, 'parameter', 'PeriodName').value + ' - Sollwert'"> -->
                        <Panel>
                            <div class="formgrid grid p-1">
                                <div class="field col-12">
                                    <div class="flex justify-content-center">
                                        <p class="text-4xl fg-yellow">{{ this.getDiagDataPhotons(index, 'presetName').value }}</p>
                                    </div>
                                </div>
                            </div>
                        </Panel>    
                        <Panel :header="`${this.getDiagDataPhotons(index, 'presetName').value} - Konfiguration Spektrum/Leuchtkraft`" class="mt-2">
                            <template #icons>
                                <moduleDialogIconHelp icon="fi fi-rr-temperature-low" text="<p>Der <b>Photonenmixer</b> ermöglicht bei deaktiviertem Preset eine individuelle Spektrumskonfiguration pro Leuchte. Ist der Preset-Schalter aktiviert, so kann man eines der vordefinierten Presets auswählen und zuordnen.</p>" ></moduleDialogIconHelp>
                            </template>

                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="lampChannel0Level" ref="lampChannel0Level" :min="0" :max="100"
                                            v-model.number="this.getDiagDataPhotonsChannel(index, `photonPreset[${index}].presetLevel[0]`).value" autofocus @focus="focusElement"
                                            :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false" suffix="%"
                                            inputClass="nice-font inputtext-param text-center text-5xl" class="bg-cobalt fg-yellow" />
                                        <Slider v-model="this.getDiagDataPhotonsChannel(index, `photonPreset[${index}].presetLevel[0]`).value" />
                                        <label for="lampChannel0Level" class="mr-2 fg-yellow">450+470nm blau</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="lampChannel1Level" ref="lampChannel1Level" :min="0" :max="100"
                                            v-model.number="this.getDiagDataPhotonsChannel(index, `photonPreset[${index}].presetLevel[1]`).value" autofocus @focus="focusElement"
                                            :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false" suffix="%"
                                            inputClass="nice-font inputtext-param text-center text-5xl bg-lightGray fg-black" />
                                        <Slider v-model="this.getDiagDataPhotonsChannel(index, `photonPreset[${index}].presetLevel[1]`).value" />
                                        <label for="lampChannel1Level" class="mr-2 fg-black">6500K weiß</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid mt-3">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="lampChannel2Level" ref="lampChannel2Level" :min="0" :max="100"
                                            v-model.number="this.getDiagDataPhotonsChannel(index, `photonPreset[${index}].presetLevel[2]`).value" autofocus @focus="focusElement"
                                            :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false" suffix="%"
                                            inputClass="nice-font inputtext-param text-center text-5xl bg-red" />
                                        <Slider v-model="this.getDiagDataPhotonsChannel(index, `photonPreset[${index}].presetLevel[2]`).value" />
                                        <label for="lampChannel2Level" class="mr-2">660nm rot</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="lampChannel3Level" ref="lampChannel3Level" :min="0" :max="100"
                                            v-model.number="this.getDiagDataPhotonsChannel(index, `photonPreset[${index}].presetLevel[3]`).value" autofocus @focus="focusElement"
                                            :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false" suffix="%"
                                            inputClass="nice-font inputtext-param text-center text-5xl bg-crimson" />
                                        <Slider v-model="this.getDiagDataPhotonsChannel(index, `photonPreset[${index}].presetLevel[3]`).value" />
                                        <label for="lampChannel3Level" class="mr-2">730nm "far red"</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid mt-3">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="lampChannel4Level" ref="lampChannel4Level" :min="0" :max="100"
                                            v-model.number="this.getDiagDataPhotonsChannel(index, `photonPreset[${index}].presetLevel[4]`).value" autofocus @focus="focusElement"
                                            :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false" suffix="%"
                                            inputClass="nice-font inputtext-param text-center text-5xl bg-blue fg-yellow" />
                                        <Slider v-model="this.getDiagDataPhotonsChannel(index, `photonPreset[${index}].presetLevel[4]`).value" />
                                        <label for="lampChannel4Level" class="mr-2 fg-yellow">8800K blau/weiß</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="lampChannel5Level" ref="lampChannel5Level" :min="0" :max="100"
                                            v-model.number="this.getDiagDataPhotonsChannel(index, `photonPreset[${index}].presetLevel[5]`).value" autofocus @focus="focusElement"
                                            :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false" suffix="%"
                                            inputClass="nice-font inputtext-param text-center text-5xl bg-green" />
                                        <Slider v-model="this.getDiagDataPhotonsChannel(index, `photonPreset[${index}].presetLevel[5]`).value" />
                                        <label for="lampChannel5Level" class="mr-2">505nm verde</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid mt-3">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="lampChannel6Level" ref="lampChannel6Level" :min="0" :max="100"
                                            v-model.number="this.getDiagDataPhotonsChannel(index, `photonPreset[${index}].presetLevel[6]`).value" autofocus @focus="focusElement"
                                            :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false" suffix="%"
                                            inputClass="nice-font inputtext-param text-center text-5xl bg-lightBlue fg-black" />
                                        <Slider v-model="this.getDiagDataPhotonsChannel(index, `photonPreset[${index}].presetLevel[6]`).value" />
                                        <label for="lampChannel6Level" class="mr-2 fg-black">EQ-W</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="lampChannel7Level" ref="lampChannel7Level" :min="0" :max="100"
                                            v-model.number="this.getDiagDataPhotonsChannel(index, `photonPreset[${index}].presetLevel[7]`).value" autofocus @focus="focusElement"
                                            :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false" suffix="%"
                                            inputClass="nice-font inputtext-param text-center text-5xl bg-darkAmber" />
                                        <Slider v-model="this.getDiagDataPhotonsChannel(index, `photonPreset[${index}].presetLevel[7]`).value" />
                                        <label for="lampChannel7Level" class="mr-2">3000K</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1" v-show="false">
                                <div class="field col-3"></div>
                                <div class="field col-6">
                                    <div class="flex justify-content-center mt-3">
                                        <Button label="Auf alle Leuchten in der Abteilung kopieren" @click="copyChannelsConfirmDiagOpen(index)" />
                                    </div>
                                </div>
                                <div class="field col-3"></div>
                            </div>
                        </Panel>  
                        <div class="formgrid grid p-1">
                            <div class="field col-3"></div>
                            <div class="field col-6">
                                <div class="flex justify-content-center mt-3">
                                    <Button label="Preset-Namen ändern" @click="toogleLampNameDiag(this.getDiagDataPhotons(index, 'presetName'))" />
                                </div>
                            </div>
                            <div class="field col-3"></div>
                        </div>
                        
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <div class="field">
                    <div class="formgrid grid p-1 align-items-center">
                        <div class="field col-9">
                            <InlineMessage severity="info" class="mb-3 ml-3 mr-3 fg-yellow bg-black" :style="{ border: 'solid #fff44d', borderWidth: '0 0 0 6px' }">
                                Wird der Schalter aktiviert und anschließend der Dialog gespeichert, werden alle Leuchten mit ihren Photonen-Presets initialisiert. Hierfür müssen alle Leuchten aktiviert werden (Strom ein), da sonst die Einstellungen nicht übertragen werden können.
                            </InlineMessage>
                        </div>
                        <div class="field col-3">
                            <div class="field col flex justify-content-center">
                                <div class="flex align-items-center">
                                    <InputSwitch v-model="this.getDiagDataCfg('photonInit').value" /><span class="ml-2">Initialisieren</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <widgetDialogFooter :widgetName="`${this.$options.name}_photonmix`" :department="moduleDepartment.key" :exchange="true" format="json"
                        @diagAbort="diagTogglePhotons"
                        @diagSave="saveDiag('photons')"
                        @import="($value) => {this.nodeDataPhotons = $value}"
                        :exportObject="this.nodeDataPhotons"
                    ></widgetDialogFooter>
                </div>
            </template>
        </Dialog>
        <Dialog 
            v-model:visible="showDiagPhotonsDimmer"
            @show="focusElement"
            :breakpoints="{ '960px': '85vw', '640px': '90vw' }"
            :style="{ width: '50vw' }"
            class="p-fluid"
            >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i>
                    <span class="subtext mt-1">
                        {{ moduleDepartment.key }} - {{ moduleDepartment.label }} ⚡ Photonendimmer Preset-Konfiguration
                    </span>
                </h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="diagTogglePhotonsDimmer" @keydown.enter="saveDiag('photonsDimmer')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView v-else :scrollable="true">
                    <TabPanel v-for="(element, index) in this.nodeDataPhotonsDimmer" :key="index" :header="`${index+1}: ${this.getDiagDataPhotonsDim(index, 'presetNameDim').value}`">
                        <!-- <Panel :header="returnParameter(element, 'parameter', 'PeriodName').value + ' - Sollwert'"> -->
                        <Panel>
                            <div class="formgrid grid p-1">
                                <div class="field col-12">
                                    <div class="flex justify-content-center">
                                        <p class="text-4xl fg-yellow">{{ this.getDiagDataPhotonsDim(index, 'presetNameDim').value }}</p>
                                    </div>
                                </div>
                            </div>
                        </Panel>    
                        <Panel :header="`${this.getDiagDataPhotonsDim(index, 'presetNameDim').value} - Konfiguration Leuchtkraft`" class="mt-2">
                            <template #icons>
                                <moduleDialogIconHelp icon="fi fi-rr-temperature-low" text="<p>Der <b>Photonendimmer</b> ermöglicht bei deaktiviertem Preset eine individuelle Helligkeitskonfiguration pro Leuchte. Ist der Preset-Schalter aktiviert, so kann man eines der vordefinierten Presets auswählen und zuordnen.</p>" ></moduleDialogIconHelp>
                            </template>

                            <div class="formgrid grid">
                                <div class="field col">
                                    <span class="p-float-label">
                                        <InputNumber id="lampChannel0Level" ref="lampChannel0Level" :min="0" :max="100"
                                            v-model.number="this.getDiagDataPhotonsChannelDim(index, `photonDimPreset[${index}].presetLevelDim[0]`).value" autofocus @focus="focusElement"
                                            :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false" suffix="%"
                                            inputClass="nice-font inputtext-param text-center text-5xl fg-black" class="bg-lightGray" />
                                        <Slider v-model="this.getDiagDataPhotonsChannelDim(index, `photonDimPreset[${index}].presetLevelDim[0]`).value" />
                                        <label for="lampChannel0Level" class="mr-2 fg-black">Leuchtstärke</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1" v-show="false">
                                <div class="field col-3"></div>
                                <div class="field col-6">
                                    <div class="flex justify-content-center mt-3">
                                        <Button label="Auf alle Leuchten in der Abteilung kopieren" @click="copyChannelsConfirmDiagOpen(index)" />
                                    </div>
                                </div>
                                <div class="field col-3"></div>
                            </div>
                        </Panel>  
                        <div class="formgrid grid p-1">
                            <div class="field col-3"></div>
                            <div class="field col-6">
                                <div class="flex justify-content-center mt-3">
                                    <Button label="Preset-Namen ändern" @click="toogleLampNameDiag(this.getDiagDataPhotonsDim(index, 'presetNameDim'))" />
                                </div>
                            </div>
                            <div class="field col-3"></div>
                        </div>
                        
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <div class="field">
                    <div class="formgrid grid p-1 align-items-center">
                        <div class="field col-9">
                            <InlineMessage severity="info" class="mb-3 ml-3 mr-3 fg-yellow bg-black" :style="{ border: 'solid #fff44d', borderWidth: '0 0 0 6px' }">
                                Wird der Schalter aktiviert und anschließend der Dialog gespeichert, werden alle Leuchten mit ihren Photonen-Presets initialisiert. Hierfür müssen alle Leuchten aktiviert werden (Strom ein), da sonst die Einstellungen nicht übertragen werden können.
                            </InlineMessage>
                        </div>
                        <div class="field col-3">
                            <div class="field col flex justify-content-center">
                                <div class="flex align-items-center">
                                    <InputSwitch v-model="this.getDiagDataCfg('photonInit').value" /><span class="ml-2">Initialisieren</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <widgetDialogFooter :widgetName="`${this.$options.name}_photondim`" :department="moduleDepartment.key" :exchange="true" format="json"
                        @diagAbort="diagTogglePhotonsDimmer"
                        @diagSave="saveDiag('photonsDimmer')"
                        @import="($value) => {this.nodeDataPhotonsDimmer = $value}"
                        :exportObject="this.nodeDataPhotonsDimmer"
                    ></widgetDialogFooter>
                </div>
            </template>
        </Dialog>

        <div v-if="!isLoading">
            <div class="flex justify-content-between align-items-center p-0 flex-wrap">
                <div class="flex justify-content-start align-items-center p-0">
                    <moduleIconEnat :icon="moduleData.metadata.icon" :alarmStatus="alarmStatus" :alarmQueue="alarmQueue" @toogleAlarmOverlay="toogleAlarmOverlay"></moduleIconEnat>
                    <div class="flex mt-1 ml-2">
                        <div class="flex flex-column mr-2">

                        </div>
                    </div>
                    <div class="flex flex-column mt-3">

                    </div>
                </div>
                <div class="flex justify-content-end align-items-center p-0">
                    <widget-header :value="``" :moduleData="this.moduleData" :fgColor="fontColor" />
                </div>
            </div>

            <div class="stats flex mt-1">
                <div class="left">
                    <div class="flex-1 align-items-center justify-content-center mt-3">
                        <button-enat title="Presets Photonenmixer" subtitle="Einstellungen Spektren" icon="fi fi-rr-settings-sliders" fgColor="darkGrayBlue" bgColor="lightAmber"
                            @click="toggleOP($event, 'photons')" >
                        </button-enat>
                        <p class="text-small mt-3">Der <b>Photonenmixer</b> ermöglicht die Konfiguration der 16 Voreinstellungen (Presets) aller spektrumgesteuerten 8-Kanal Leuchten.</p>
                    </div>
                </div>
                <div class="right">
                    <div class="flex-1 align-items-center justify-content-center mt-3">
                        <button-enat title="Presets Photonendimmer" subtitle="Einstellungen Dimmprofile" icon="fi fi-rr-settings-sliders" fgColor="darkGrayBlue" bgColor="lightAmber"
                            @click="toggleOP($event, 'photonsDimmer')" >
                        </button-enat>
                        <p class="text-small mt-3">Der <b>Photonendimmer</b> ermöglicht die Konfiguration der 16 Voreinstellungen (Presets) aller dimmbaren 1-Kanal Leuchten.</p>
                    </div>
                </div>
            </div>
        </div>
        <Dialog 
            v-model:visible="showDiagLedName"
            :closable="false"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '450px' }"
            :modal="true"
            class="p-fluid"
        >
            <template #header>
                <p class="nice-font"><i class="fi fi-rr-text"></i><span class="pl-1 mt-1">Preset-Bezeichnung ändern</span></p>
            </template>
            <div @keydown.esc="hideLampNameDiag" @keydown.enter="saveLampNameDiag(changeDiagLedName)">
                <Panel>
                    <div class="grid p-fluid">
                        <div class="col-12 pl-5 pr-5">
                            <span class="p-float-label mr-2">
                                <InputText id="lampName" ref="lampName" v-model="changeDiagLedName.value" locale="de-DE" class="nice-font inputtext-param text-center text-2xl" />
                                <label for="lampName" class="mr-2">Preset-Name</label>
                            </span>
                        </div>
                    </div>
                </Panel>
            </div>
            <template #footer>
            <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideLampNameDiag" />
            <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveLampNameDiag(changeDiagLedName)" />
            </template>
        </Dialog>
        <Dialog
            v-model:visible="copyChannelsConfirmDiag"
            :style="{ width: '450px' }"
            header="Bestätigung"
            :modal="true"
            >
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="ioDialogData">Sollen die aktuellen Kanaleinstellungen wirklich auf alle {{ this.nodeDataPhotons.length }} Leuchten übertragen werden?</span>
            </div>
            <template #footer>
                <Button
                label="Nein"
                icon="pi pi-times"
                class="p-button-text"
                @click="copyChannelsConfirmDiagClose()"
                />
                <Button
                label="Ja"
                icon="pi pi-check"
                class="p-button-text"
                @click="copyLevelToAll(this.copyChannelIndex)"
                />
            </template>
        </Dialog>
        <div v-if="entryDialog === true">
            <dialog-io-single v-bind:showDialog="entryDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                " @entryAbort="hideDialog()"></dialog-io-single>
        </div>
        <div v-if="entryDialog === true">
            <dialog-io-single v-bind:showDialog="entryDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                " @entryAbort="hideDialog()"></dialog-io-single>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import store from '@/store';
import { mapGetters, mapActions } from 'vuex';
import helpers from '@/helpers';
import { parseNode, setOpcValueToast } from '@/helpers';
import SocketioService from '@/services/socketioService.js';
// import widgetAlarmOutput from '@/components/fragments/widgetAlarmOutput.vue';
import widgetDialogFooter from '@/components/fragments/widgetDialogFooter.vue';
// import Widget_rowValues2 from '@/components/fragments/widget_rowValues2.vue';
// import dialogChartSingle from '@/components/dialog/chartSingle.vue';
// import widgetTimeSet from '@/components/fragments/widgetTimeSet.vue';
import moduleDialogIconHelp from '@/components/fragments/moduleDialogIconHelp.vue';
import moduleIconEnat from '@/components/fragments/moduleIconEnat.vue';
import widgetTitleBar from '@/components/fragments/widgetTitleBar.vue';

export default defineComponent({
    name: 'widgetIlluminationPhotonMixer',
    setup() { },
    components: {
        widgetDialogFooter,
        moduleDialogIconHelp,
        moduleIconEnat,
        widgetTitleBar,
        // Widget_rowValues2,
        // dialogChartSingle,
    },
    props: {
        content: {
            type: null,
            required: true,
        },
        dragObj: {
            type: null,
            required: false,
        },
        valueObj: {
            type: null,
            required: false,
        },
        dataObj: {
            type: null,
            required: false,
        },
        styleObj: {
            type: null,
            required: false,
        },
        createdLayout: {
            type: Boolean,
            required: false,
        },
        readyLayout: {
            type: Boolean,
            required: false,
        },
    },
    watch: {
        createdLayout() {
            this.watchCreatedLayout();
        },
        readyLayout() {
            this.watchLayout();
        },
        '$store.getters.getPageData.editMode': function () {
            this.checkEditMode();
        },
        // nodeEntry: {
        //     handler: function(entry) {
        //         // this.filterParameters();
        //         // if (Array.isArray(entries) && entries.length > 0) {
        //         //     this.loading = false;
        //         // } else {
        //         //     this.loading = true;
        //         // }
        //     },
        //     deep: true
        // }
    },
    computed: {
        ...mapGetters({
            editMode: 'getEditMode',
            getNodeWidget: 'getNodeWidget',
            getOneDepartment: 'types/getOneDepartment',
            getOneCategory: 'types/getOneCategory',
            getIoTree: 'getIoTree',
            getStyleColors: 'getStyleColors',
            getStylePatterns: 'getStylePatterns',
            getModules: 'opcua/getModules',
            getModule: 'opcua/getModule',
            getNode: 'opcua/getNode',
            getModuleFetched: 'opcua/getModuleFetched',
            isMaster: 'auth/isMaster',
        }),
        isLoading: function() {
            if (!this.moduleData || !this.getModuleFetched(this.moduleHash) || this.loading) return true;
            return false;
        },
        
        connectedModule: function () {
            if (this.content.data.module) {
                return this.content.data.module;
            }
            return null;
        },
        moduleData: function () {
            if (this.connectedModule) {
                const module = this.getModule(this.connectedModule);
                if (module) return module;
            }
            return null;
        },
        moduleHash: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.source.server !== null && this.moduleData.source.identifier !== null) {
                    return `${this.moduleData.source.server}@${this.moduleData.source.identifier}`
                }
            }
            return null;
        },
        moduleDepartment: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.department !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.department);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        moduleCategory: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.category !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.category);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        componentName: function () {
            if (this.content.name !== undefined) {
                return this.content.name;
            } else {
                return '???';
            }
        },
        componentLabel: function () {
            if (this.content.label !== undefined) {
                return this.content.label;
            } else if (this.moduleData !== null && this.moduleData.metadata.label) {
                return this.moduleData.metadata.label;
            } else {
                return store.getters.getNoval;
            }
        },
        componentContent: function () {
            if (this.content.content !== undefined) {
                return this.content.content;
            } else {
                return '???';
            }
        },
        componentStyle: function () {
            if (this.content.style && this.content.style !== 'standard') {
                return 'box ptrn-' +  this.content.style.pattern + ' bg-' + this.content.style.background + ' fg-' + this.content.style.foreground;
            } else {
                return '';
            }
        },
        fontColor: function () {
            if (this.content.style && this.content.style.foreground) {
                return `fg-${this.content.style.foreground}`;
            }
            return 'gray';
        },
        alarmStatus: function () {
            let alarmCount = this.alarmEntriesCount;
            for (let i=0; i<this.alarmEntriesCount; i++) {
                // console.log(this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`));
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value <= 0) {
                    alarmCount -= 1;
                }
            }
            alarmCount = 0;
            return alarmCount;
        },
        alarmQueue: function () {
            let alarmCount = this.alarmEntriesCount;
            for (let i=0; i<this.alarmEntriesCount; i++) {
                // console.log(this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`));
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value === 0) {
                    alarmCount -= 1;
                }
            }
            return alarmCount;
        },
        alarmList: function () {
            let activeAlarms = [];
            for (let i=0; i<this.alarmEntriesCount; i++) {
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value !== 0) {
                    const alarm = {
                        label: this.getModuleValue(`AlarmEntries[${i}].CFG.alarmLabel`).value,
                        state: this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value
                    }
                    activeAlarms.push(alarm);
                }
            }
            return activeAlarms;
        },
        alarmTicker: function () {
            let alarmStr = '';
            for (let i=0; i<this.alarmEntriesCount; i++) {
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value > 0) {
                    alarmStr += `🔴 ${this.getModuleValue(`AlarmEntries[${i}].CFG.alarmLabel`).value} `;
                }
            }
            return alarmStr;
        },
        icon: function () {
            if (this.alarmStatus === 0) {
                return this.moduleData.metadata.icon
            } else {
                return `${this.moduleData.metadata.icon} fg-red pulse-loop cursor-pointer bg-lightCrimson`;
            }
        },
        photonPreset: function () {
            let arr = [];
            for (let i=0; i<=15; i++) {
                const channel = {
                    label: `[${i+1}] ` + this.getModuleValue(`IN_PhotonMix[${i}].presetName`).value,
                    value: i
                }
                arr.push(channel);
            }
            //console.log(this.getModuleValue('IN_PhotonMix[0].presetName'));
            return arr;
        },
    },
    data() {
        return {
            loading: false,
            showChart1: false,
            saveWait: false,
            dialogLoading: false,
            entryDialog: false,
            valveDialog: false,
            ioDialogData: {},
            ioObj: {},
            timeSetGrp: null,
            nodeDataCfg: [],
            nodeDataCfgOld: [],
            nodeDataPhotons: [],
            nodeDataPhotonsOld: [],
            nodeDataPhotonsDimmer: [],
            nodeDataPhotonsDimmerOld: [],
            showDiagName: false,
            showDiagLedName: false,
            showDiagLedId: false,
            changeDiagName: null,
            changeDiagLedName: null,
            changeDiagLedId: null,
            copyChannelsConfirmDiag: false,
            copyChannelIndex: null,
            photonMixerPresets: [],
            photonDimmerPresets: [],
            daliChannels: [
                { label: `Kanal 1`, value: 0 },
                { label: `Kanal 2`, value: 1 },
                { label: `Kanal 3`, value: 2 },
                { label: `Kanal 4`, value: 3 },
                { label: `Kanal 5`, value: 4 },
                { label: `Kanal 6`, value: 5 },
                { label: `Kanal 7`, value: 6 },
                { label: `Kanal 8`, value: 7 },
            ],
            currentGrp: {
                name: null,
                label: null,
                enable: null,
                timeSet: null,
                limits: {},
                controller: {},
            },
            node: null,
            lala: null,
            showDiagPeriods: false,
            showDiagPhotons: false,
            showDiagPhotonsDimmer: false,
            showDiagMisc: false,
            submitted: false,
            selectedKey: {},
            expandedKeys: {},
            items: [],
            items1: [
                {
                    label: 'Presets Photonenmixer',
                    icon: 'pi pi-cog',
                    command: () => {
                        // this.editEntry(this.content);
                        this.toggleOP(null, 'photons');
                    },
                },
                {
                    label: 'Presets Photonendimmer',
                    icon: 'pi pi-cog',
                    command: () => {
                        // this.editValve(this.content);
                        this.toggleOP(null, 'photonsDimmer');
                    },
                },
            ],
            items2: [
                {
                    separator: true,
                },
                {
                    label: 'Widget Config',
                    icon: 'pi pi-cog',
                    command: () => {
                        this.editEntry(this.content);
                    },
                },
                {
                    label: 'Duplizieren',
                    icon: 'pi pi-clone',
                    command: () => {
                        this.duplicateEntry();
                    },
                },
                {
                    label: 'Löschen',
                    icon: 'pi pi-times',
                    command: () => {
                        this.deleteEntry();
                    },
                },
            ],
        };
    },
    created() {
        this.loading = true;
    },
    mounted() {
        this.checkEditMode();
        if (!this.getModuleFetched(this.moduleHash)) {
            this.loadModule(this.connectedModule).then((module) => {
                const moduleHash = `${module.source.server}@${module.source.identifier}.*`;
                SocketioService.getRedisNodesParam(moduleHash, (err, response) => {
                    if (!err && response) {
                        if (Array.isArray(response) && response.length > 0) {
                            this.setNodesFull(response);
                        }
                    }
                }).then(() => { 
                    if (this.editMode) console.log(`[ENATWIDGET] ${this.moduleHash} : component ready!`);
                    this.setModuleFetched(this.moduleHash);
                    this.loading = false;
                });
            });
        } else {
            this.loading = false;
        }
    },
    methods: {
        ...mapActions({
            loadNode: 'opcua/loadNode',
            loadModule: 'opcua/loadModule',
            setNodesFull: 'opcua/setNodesFull',
            setModuleFetched: 'opcua/setModuleFetched',
        }),
        loadingState(state) {
            if (state) {
                this.loading = true;
            } else {
                this.loading = false;
            }
        },
        getModuleValue(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            return this.getNode(key);
        },
        getModuleValueStr(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            const node = this.getNode(key);
            if (typeof node.unit === 'string' && node.unit.length > 0) {
                return `${node.value}${node.unit}`;
            }
            return `${node.value}`;
        },
        getDiagData(key) {
            if (this.nodeData.length > 0) {
                const result = this.nodeData.find(entry => entry.key2 === key);
                return result;
            }
            return null;
        },
        getDiagDataPeriods(index, displayName) {
            if (this.nodeDataPeriods.length > 0 && index >= 0) {
                const result = this.nodeDataPeriods[index].find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataCfg(displayName) {
            if (this.nodeDataCfg.length > 0 && displayName) {
                const result = this.nodeDataCfg.find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataPhotons(index, displayName) {
            if (this.nodeDataPhotons.length > 0 && index >= 0) {
                const result = this.nodeDataPhotons[index].find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataPhotonsDim(index, displayName) {
            if (this.nodeDataPhotonsDimmer.length > 0 && index >= 0) {
                const result = this.nodeDataPhotonsDimmer[index].find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataPhotonsChannel(index, arrayKey) {
            if (this.nodeDataPhotons.length > 0 && index >= 0) {
                const result = this.nodeDataPhotons[index].find(entry => entry.arrayKey === arrayKey);
                return result;
            }
            return null;
        },
        getDiagDataPhotonsChannelDim(index, arrayKey) {
            if (this.nodeDataPhotonsDimmer.length > 0 && index >= 0) {
                const result = this.nodeDataPhotonsDimmer[index].find(entry => entry.arrayKey === arrayKey);
                return result;
            }
            return null;
        },
        watchCreatedLayout() {
            // this.loading = true;
        },
        watchLayout() {
        },
        checkEditMode() {
            if (this.$store.getters.getPageData.editMode) {
                this.items = [...this.items1, ...this.items2];
            } else {
                this.items = [...this.items1];
            }
        },
        clearVals() {
            this.nodeDataPhotons = [];
            this.nodeDataPhotonsOld = [];
            this.nodeDataPhotonsDimmer = [];
            this.nodeDataPhotonsDimmerOld = [];
            this.nodeDataCfg = [];
            this.nodeDataCfgOld = [];
            this.saveWait = false;
            this.dialogLoading = false;
        },
        updateMap() {
            // this.node = helpers.mapNodes(_.cloneDeep(this.nodeEntry.children), null);
            if (this.node && this.node.size > 0) {
                for (let [key, element] of this.node) {
                    const child = store.getters.getMapValue(element.uuid);
                    this.node.set(key, {...child, value: child.value});
                }
            }
        },
        currentMap() {
            let map = new Map();
            if (this.node && this.node.size > 0) {
                for (let [key, element] of this.node) {
                    const child = store.getters.getMapValue(element.uuid);
                    map.set(key, {...child, value: child.value});
                }
                return map;
            }
            return null;
        },
        getParameter(lvl1, lvl2, index) {
            let parameter = null;
            if (!lvl1) return false;
            if (!lvl2) lvl2 = '';
            if (!isNaN(index)) {
                parameter = this.node.get(lvl1 + '[' + index + '].' + lvl2);
            } else {
                parameter = this.node.get(lvl1 + lvl2);
            }
            return parameter;
        },
        returnChild(childStr) {
            if (typeof childStr == 'string' && this.nodeEntry !== undefined) {
                return helpers.returnChild(this.nodeEntry, childStr);
            } else {
                return null;
            }
        },
        returnParameter(node, key, parameter) {
            return helpers.returnParameter(node, key, parameter);
        },
        returnParameterValue(node, key, parameter, unit) {
            return helpers.returnParameterValue(node, key, parameter, unit);
        },
        timeDialog(time) {
            console.log(time);
        },
        toogleAlarmOverlay(event) {
            this.$refs.alarmOverlay.toggle(event);
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
            // this.this.getModuleValueStatusIOTree();
            // this.getOPCAppTree();
        },
        toggleOP(event, grp) {
            if (grp === 'photons' || grp === 'photonsDimmer') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            let tmpNodes = [];
                            this.clearVals();
                            tmpNodes = JSON.parse(JSON.stringify(response));
                            tmpNodes.forEach((entry) => {
                                if (entry.child1) {
                                    const child = JSON.parse(entry.child1)
                                    if (child.index >= 0 && child.arrayName === "photonPreset") {
                                        if (!Array.isArray(this.nodeDataPhotons[child.index])) {
                                            this.nodeDataPhotons[child.index] = [];
                                        }
                                        this.nodeDataPhotons[child.index].push(entry);
                                    } else if (child.index >= 0 && child.arrayName === "photonDimPreset") {
                                        if (!Array.isArray(this.nodeDataPhotonsDimmer[child.index])) {
                                            this.nodeDataPhotonsDimmer[child.index] = [];
                                        }
                                        this.nodeDataPhotonsDimmer[child.index].push(entry);
                                    }
                                } else {
                                    if (entry.value !== null) this.nodeDataCfg.push(entry);
                                }
                                // console.log(entry);
                            });
                            this.nodeDataPhotonsOld = JSON.parse(JSON.stringify(this.nodeDataPhotons));
                            this.nodeDataPhotonsDimmerOld = JSON.parse(JSON.stringify(this.nodeDataPhotonsDimmer));
                            this.nodeDataCfgOld = JSON.parse(JSON.stringify(this.nodeDataCfg));
                            console.log(this.nodeDataPhotons);
                            console.log(this.nodeDataPhotonsOld);
                            console.log(this.nodeDataPhotonsDimmer);
                            console.log(this.nodeDataPhotonsDimmerOld);
                            console.log(this.nodeDataCfg);
                            console.log(this.nodeDataCfgOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                    }
                }).then(() => {
                    if (grp === 'photons') this.showDiagPhotons = true;
                    if (grp === 'photonsDimmer') this.showDiagPhotonsDimmer = true;
                }); 
            }
        },
        diagTogglePhotons() {
            if (this.showDiagPhotons) {
                this.showDiagPhotons = false;
            } else {
                this.showDiagPhotons = true;
            }
            this.hideOP();
        },
        diagTogglePhotonsDimmer() {
            if (this.showDiagPhotonsDimmer) {
                this.showDiagPhotonsDimmer = false;
            } else {
                this.showDiagPhotonsDimmer = true;
            }
            this.hideOP();
        },
        hideOP() {
            this.clearVals();
        },
        saveDiag(diag) {
            if (diag) {
                const saveArray = [];
                this.dialogLoading = true;
                this.saveWait = true;

                if (diag === 'photons' || diag === 'photonsDimmer') {
                    console.log(this.nodeDataPhotons);
                    console.log(this.nodeDataPhotonsOld);
                    const flatHzg = this.nodeDataPhotons.flatMap(innerArr => innerArr);
                    const flatHzgOld = this.nodeDataPhotonsOld.flatMap(innerArr => innerArr);
                    console.log(flatHzg);
                    console.log(flatHzgOld);

                    flatHzg.forEach((obj1) => {
                        // Find the corresponding object in arr2
                        const obj2 = flatHzgOld.find((item) => item.identifier === obj1.identifier);
                        
                        // If the object is found, compare the values
                        if (obj2) {
                            if (obj1.value !== obj2.value) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                                if (obj2.plusminus !== obj1.plusminus) {
                                    const entry = parseNode(obj1, true);
                                    saveArray.push(entry);
                                }
                            }
                        }
                    });

                    console.log(this.nodeDataPhotonsDimmer);
                    console.log(this.nodeDataPhotonsDimmerOld);
                    const flatHzg11 = this.nodeDataPhotonsDimmer.flatMap(innerArr => innerArr);
                    const flatHzgOld11 = this.nodeDataPhotonsDimmerOld.flatMap(innerArr => innerArr);
                    console.log(flatHzg11);
                    console.log(flatHzgOld11);

                    flatHzg11.forEach((obj1) => {
                        // Find the corresponding object in arr2
                        const obj2 = flatHzgOld11.find((item) => item.identifier === obj1.identifier);
                        
                        // If the object is found, compare the values
                        if (obj2) {
                            if (obj1.value !== obj2.value) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                                if (obj2.plusminus !== obj1.plusminus) {
                                    const entry = parseNode(obj1, true);
                                    saveArray.push(entry);
                                }
                            }
                        }
                    });

                    const flatHzg2 = this.nodeDataCfg.flatMap(innerArr => innerArr);
                    const flatHzgOld2 = this.nodeDataCfgOld.flatMap(innerArr => innerArr);

                    flatHzg2.forEach((obj3) => {
                        // Find the corresponding object in arr2
                        const obj4 = flatHzgOld2.find((item) => item.identifier === obj3.identifier);
                        
                        // If the object is found, compare the values
                        if (obj4) {
                            if (obj3.value !== obj4.value) {
                                const entry = parseNode(obj3, true);
                                saveArray.push(entry);
                            } else if ('plusminus' in obj4 && 'plusminus' in obj3) {
                                if (obj4.plusminus !== obj3.plusminus) {
                                    const entry = parseNode(obj3, true);
                                    saveArray.push(entry);
                                }
                            }
                        }
                    });
                }
                
                // console.log(saveArray);
                if (saveArray.length > 0) {
                    SocketioService.setOpcValue(saveArray, (err, response) => {
                        if (err) {
                            console.log(err);
                        } else {
                            console.log(response);
                            setOpcValueToast(response, this.$root);
                            this.dialogLoading = false;
                            if (diag === 'photons') this.showDiagPhotons = false;
                            if (diag === 'photonsDimmer') this.showDiagPhotonsDimmer = false;
                            this.hideOP();
                        }
                    });
                } else {
                    setOpcValueToast(null, this.$root);
                    this.dialogLoading = false;
                    if (diag === 'photons') this.showDiagPhotons = false;
                    if (diag === 'photonsDimmer') this.showDiagPhotonsDimmer = false;
                    this.hideOP();
                }
            }
        },
        toogleLampNameDiag(name) {
            this.changeDiagLedName = JSON.parse(JSON.stringify(name));
            this.showDiagLedName = true;
        },
        hideLampNameDiag() {
            this.changeDiagLedName = null;
            this.showDiagLedName = false;
        },
        saveLampNameDiag(node) {
            console.log(node);
            if (node.child0) {
                const child = JSON.parse(node.child1);
                if (child.index >= 0 && child.arrayName === 'photonPreset') {
                    console.log(child.index)
                    let obj2 = null;
                    obj2 = this.nodeDataPhotons[child.index].find((item) => item.identifier === node.identifier);
                    if (obj2) {
                        if (typeof this.changeDiagLedName.value === 'string' && this.changeDiagLedName.value.length > 0) {
                            obj2.value = this.changeDiagLedName.value;
                        }
                        console.log(obj2);
                    }
                } else if (child.index >= 0 && child.arrayName === 'photonDimPreset') {
                    console.log(child.index)
                    let obj2 = null;
                    obj2 = this.nodeDataPhotonsDimmer[child.index].find((item) => item.identifier === node.identifier);
                    if (obj2) {
                        if (typeof this.changeDiagLedName.value === 'string' && this.changeDiagLedName.value.length > 0) {
                            obj2.value = this.changeDiagLedName.value;
                        }
                        console.log(obj2);
                    }
                }
            }
            this.hideLampNameDiag();
        },
        copyChannelsConfirmDiagOpen(index) {
            this.copyChannelsConfirmDiag = true;
            this.copyChannelIndex = index;
        },
        copyChannelsConfirmDiagClose() {
            this.copyChannelsConfirmDiag = false;
            this.copyChannelIndex = null;
        },
        copyLevelToAll(lampIndex) {
            // this.getDiagDataPhotonsChannel(index, `CFG_DaliChannels[${index}].lampDaliChannels[7].daliLevel`).value
            if (Array.isArray(this.nodeDataPhotons) && this.nodeDataPhotons.length > 0 && lampIndex >= 0) {
                // console.log(lampIndex);
                this.nodeDataPhotons.forEach((entry, index) => {
                    // console.log(index);
                    for (let i=0; i<=7; i++) {
                        // const obj1 = this.nodeDataPhotons[index].find((item) => item.arrayKey === `CFG_DaliChannels[${lampIndex}].lampDaliChannels[${i}].daliLevel`);
                        const obj1 = this.getDiagDataPhotonsChannel(lampIndex, `CFG_DaliChannels[${lampIndex}].lampDaliChannels[${i}].daliLevel`);
                        // console.log(obj1);
                        let obj2 = null;
                        obj2 = this.nodeDataPhotons[index].find((item) => item.arrayKey === `CFG_DaliChannels[${index}].lampDaliChannels[${i}].daliLevel`);
                        obj2.value = obj1.value;
                        // console.log(obj2);
                    }
                });
                // for (let i=0; i<this.nodeDataPhotons.lenght; i++) {
                //     console.log(this.nodeDataPhotons);
                //     // for (let j=0; j<7; j++) {
                //     //     // this.getDiagDataPhotonsChannel(index, `CFG_DaliChannels[${index}].lampDaliChannels[7].daliLevel`).value
                        
                //     // }
                // }
            }
            this.copyChannelsConfirmDiagClose();
        },
        focusElement(event) {
            if (event !== undefined) {
                event.target.select();
            }
        },
        hideDialog() {
            this.entryDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        resetDialogData() {
            this.ioDialogData = {
                style: {},
                components: {},
                data: {},
            };
            this.selectedKey = {};
            this.expandedKeys = {};
        },
        changeVal(ioData) {
            console.log(ioData);
            this.$emit('entrySave', ioData);
        },
        editEntry(ioData) {
            this.ioDialogData = JSON.parse(JSON.stringify(ioData));
            this.ioObj = JSON.parse(JSON.stringify(this.moduleData));
            this.entryDialog = true;
        },
        saveDialog() {
            this.submitted = true;
            if (this.ioDialogData.i) {
                this.$emit('entrySave', this.ioDialogData);
                this.entryDialog = false;
                this.resetDialogData();
            }
        },
        duplicateEntry() {
            this.$emit('entryDuplicate', this.content);
            this.resetDialogData();
        },
        deleteEntry() {
            this.$emit('entryDelete', this.content);
            this.resetDialogData();
        },
        expandNode(node) {
            this.expandedKeys[node.key] = true;
            if (node.children && node.children.length) {
                for (let child of node.children) {
                    this.expandNode(child);
                    if (child.key === this.ioObj._id) {
                        this.selectedKey[this.ioObj._id] = true;
                    }
                }
            }
        },
        onNodeSelect(node) {
            const newObj = store.getters.getValue(node.key);
            this.selectedKey = node.key;
            this.ioDialogData.data.id = node.key;

            this.$toast.add({
                severity: 'success',
                summary: 'Node ausgewählt',
                detail: newObj.metadata.name + '\n' + this.selectedKey + '\n' + newObj.nodeId,
                life: 3000,
            });
        },
        editValve(ioData) {
            console.log(ioData);
        },
        hideValveDialog() {
            this.valveDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        showChart(value) {
        if (value === 'value1') this.showChart1 = true;
        },
    },
});
</script>

<style lang="scss" scoped>
.bounce-enter-active {
    animation: bounce-in 0.5s;
    // transition: opacity .5s;
}

.bounce-leave-active {
    animation: bounce-out 0.5s;
    // transition: opacity .5s;
}

.slide-fade-enter-active {
    transition: all 0.5s ease;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active for <2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
}

.pulse-enter-active {
    animation: pulse 1s;
}

.pulse-leave-active {
    animation: pulse 0.5s;
}

.pulse-loop {
    animation: pulse 1s;
    animation-iteration-count: infinite;
}

.fade-in-enter-active {
    animation: fadeIn linear 2s;
}

.fade-in-leave-active {
    animation: fadeOut linear 2s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeInOut {
    0% {
        // opacity: 0;
    }
    50% {
        // opacity: 1;
        background-color: rgba(117, 18, 0, 0.5);
    }
    100% {
        // opacity: 0;
    }
}

@keyframes pulse {
    0% {
        // transform: scale(1); /* scaling to 0 */
        opacity: 0.8;
        // color: yellowgreen;
        // transform-origin: center center;
        // transform: scaleY(.9);
        text-shadow: black 2px 0 10px;
        transform: scale(0.9);
        /* increasing the size */
    }

    30% {
        transform: scale(1.1);
        /* increasing the size */
        text-shadow: gray 1px 0 10px;
    }

    100% {
        transform: scale(1);
        /* seeting back to initial size */
        opacity: 1;
    }
}

.alarm {
    animation: fadeInOut 1s infinite;
    -webkit-animation: fadeInOut 1s infinite;
}

.grid {
    margin: 0 -0.5em 0 -0.5em !important;
}

.col {
    // line-height: 1.4em !important;
    margin: 0 !important;
    padding: 0 !important;
}

.p-divider {
    margin: 0 !important;
    padding: 0 !important;
}

.box {
    overflow: hidden;
    // text-shadow: 1px 1px 1px black;
}

.card {
    font-family: 'SensorFont';
    line-height: 1.3rem;
    position: relative;
}

.text-normal {
    line-height: 0;
}
// .list-enter-active,
// .list-leave-active {
//   transition: all 0.5s ease;
//   transform: scale(0.7); /* increasing the size */
// }
// .list-enter-from,
// .list-leave-to {
//   opacity: 0;
//   transform: scale(1); /* increasing the size */
// }
.list-enter-active {
    animation: list 1s;
}

.list-leave-active {
    animation: list 0.5s;
}

@keyframes list {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.p-togglebutton {
    font-size: 0.8rem;
    line-height: 1rem;
    padding: 0.2rem;
    margin: 0;
}
</style>